<template>
	<div>
		<FullCalendar :events="events" :options="options"/>


		<Dialog v-model:visible="eventDialog"  style="width: 30vw" header="Cita" :modal="true">
            <div v-if="changedEvent">
				<h5>Paciente: {{changedEvent.paciente}}</h5>
				<h5>{{changedEvent.especialidad}}</h5>
				<h5>{{changedEvent.especialista}}</h5>
				<h5>{{changedEvent.dia}}, {{changedEvent.fechad}}</h5>
				<h5>{{changedEvent.fechad}}</h5>
				<div>
					<span class="p-tag p-tag-danger" v-if="(!changedEvent.procesado && !changedEvent.pagado)">Cita Pendiente</span>
					<span class="p-tag p-tag-warning"  v-if="changedEvent.procesado && !changedEvent.pagado">Pendiente el Pago</span>
					<span class="p-tag p-tag-success"  v-if="changedEvent.procesado && changedEvent.pagado">Cita Pagada</span>
					<span class="p-tag p-tag-info"  v-if="!changedEvent.procesado && changedEvent.pagado">Cita Pagada</span>
				</div>




			</div>
		</Dialog>


	</div>
</template>

<script>
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
    props: {
		events: {
			type: Object,
			default: null,
		},
	},
    data() {
        return {
			cita: null,
			eventDialog: false,
			clickedEvent: null,
			changedEvent: null,
			options: {
                locales: 'es',
				eventTimeFormat: { // like '14:30:00'
					hour: '2-digit',
					minute: '2-digit',
					hour12: true
				},
				//timeFormat: 'H(:mm)t' ,
				//hour12: true,
				plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
				defaultDate: '2019-01-01',
				header: {
					left: 'prev,next',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				},
				editable: true,
				eventClick: (e) => {
					this.eventDialog = true;
					this.clickedEvent = e.event;
					this.changedEvent = this.events.filter(val => val.id = this.clickedEvent.id)[0];
				}
			},
        }
    },
    created() {      
        this.options.defaultDate = this.events[0].fecha;
    },
    methods: {

    },
    computed: {


    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>